import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from 'firebase/database';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2DoJg--UkZJJoNZgOAllbOF06pkdsyZw",
  authDomain: "dialogue-app-010220.firebaseapp.com",
  databaseURL: "https://dialogue-app-010220.firebaseio.com",
  projectId: "dialogue-app-010220",
  storageBucket: "dialogue-app-010220.appspot.com",
  messagingSenderId: "398202203794",
  appId: "1:398202203794:web:7fd5eb6162bb90e0a9404b",
  measurementId: "G-PWB83V9HD9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app);

export { db, analytics };
