import {
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  makeStyles,
  createStyles,
  CircularProgress,
} from "@material-ui/core";
import SuccessImage from "../../assets/circle_success.svg";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TextField from "../../components/TextField";
import axios from "axios";
import services from "../../configs/services";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "100vh",
    },
    card: { padding: "60px 100px" },
    cardSuccess: { padding: "60px 170px" },
    cardMobile: { padding: "60px 20px" },
    cardContent: { padding: 0 },
    header: { fontWeight: "bold" },
    body: { textAlign: "center" },
    image: { marginTop: 46, marginBottom: 80 },
    button: {
      backgroundColor: "#ff3867",
      borderRadius: 30,
      color: "#fff",
      width: 212,
      height: 60,
      "&:hover": {
        color: "#ff3867",
        backgroundColor: "#fff",
        border: "1px solid #ff3867",
      },
    },
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: `gray !important`,
      },
    },
    cssFocused: {},
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "gray !important",
    },
    helperText: {
      marginLeft: 0,
      fontSize: 10,
    },
    password: { marginTop: 64, marginBottom: 30 },
    repeatPassword: { marginBottom: 40 },
    input: { width: 360 },
    inputMobile: { width: 240 },
  })
);

export default function ResetPassword() {
  const classes = useStyles();
  const { resetPasswordToken } = useParams();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setErrorMessage("");
  }, [password, repeatPassword]);

  function handleSave() {
    if (password !== repeatPassword) {
      setError(true);
    } else {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}${services.RESET_PASSWORD}${resetPasswordToken}`,
          {
            password,
          }
        )
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setError(true);
          setErrorMessage("Something went wrong");
          if (e.response.data) {
            setErrorMessage(
              e.response.data.errors?.password || "Something went wrong"
            );
          }
        });
    }
  }

  function handleLogin() {
    if (isMobile) {
      // APP INTENT
      window.open("dialogueapp://dialogue", "_blank");
    } else {
      // PLAYSTORE
    }
  }

  function handleChange(value, type) {
    if (type === "password") {
      setPassword(value);
    } else {
      setRepeatPassword(value);
    }
  }

  return (
    <Container maxWidth="md">
      <Grid
        container
        direction={"column"}
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <Card
            elevation={6}
            className={
              isMobile
                ? classes.cardMobile
                : !success
                ? classes.card
                : classes.cardSuccess
            }
          >
            <CardContent className={classes.cardContent}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography variant="h6" className={classes.header}>
                    {!success ? "Reset Password" : "Password Updated!"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classes.body}>
                    {!success
                      ? "Enter new password and then repeat it"
                      : "Login with your new password"}
                  </Typography>
                </Grid>
                {!success ? (
                  <>
                    <Grid item className={classes.password}>
                      <TextField
                        placeholder="New password"
                        onChange={(e) => handleChange(e, "password")}
                        error={error}
                      />
                    </Grid>
                    <Grid item className={classes.repeatPassword}>
                      <TextField
                        placeholder="New password"
                        onChange={(e) => handleChange(e, "repeat")}
                        helperText={errorMessage || "Password didn't match"}
                        error={error}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item>
                    <img
                      src={SuccessImage}
                      width={150}
                      height={150}
                      className={classes.image}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.button}
                    disableElevation
                    disabled={!password || !repeatPassword || loading}
                    onClick={!success ? handleSave : handleLogin}
                  >
                    {loading ? (
                      <CircularProgress color="secondary" size={20} />
                    ) : !success ? (
                      "Save"
                    ) : (
                      "Login"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
