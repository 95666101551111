import { makeStyles, createStyles } from "@material-ui/core";

const useButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: '#ff3867',
      borderRadius: 30,
      color: '#fff',
      width: 212,
      height: 60,
      '&:hover': {
        color: '#ff3867',
        backgroundColor: '#fff',
        border: '1px solid #ff3867',
      },
    },
  })
);

export default useButtonStyles;
