import React from 'react';
import { Container, Grid, Typography } from "@material-ui/core";

import useCommonStyles from 'styles/common';

function TermsOfService() {
  const classes = useCommonStyles();

  return (
    <Container maxWidth="md">
      <Grid item>
        <Typography variant="h2" className={classes.pageTitle}>
          Terms of Service
        </Typography>
        <Typography className={classes.mb4}>
          <b>Date of Last Revision: August 1, 2021</b>
        </Typography>
        <Typography className={classes.mb4}>
          <b>Welcome to DiaLoGue!</b><br />
          </Typography>
        <Typography className={classes.mb4}>
          Hey DiaLoGue! Welcome to our Terms of Service. The following Terms of Service serve as a contract between you and DiaLoGue. Our lawyers tell us these are important so please read them carefully (after all, you will be legally bound by them while using DiaLoGue).
        </Typography>
        <Typography className={classes.mb4}>
          <b>IF YOU PURCHASE A SUBSCRIPTION, YOUR SUBSCRIPTION WILL AUTOMATICALLY RENEW FOR THE SAME SUBSCRIPTION PERIOD/TERMS AS ORIGINALLY PURCHASED. TO AVOID ANY FUTURE UNWANTED CHARGES, YOU MUST CANCEL YOUR SUBSCRIPTION BEFORE THE EXPIRATION OF ANY FREE TRIAL PERIOD, SUBSCRIPTION PERIOD, OR RENEWAL PURSUANT TO THE TERMS AND CONDITIONS OF THIS SERVICE AND ANY THIRD-PARTY PAYMENT PLATFORMS SUCH AS ITUNES OR GOOGLE PLAY. PLEASE NOTE THAT DELETING YOUR ACCOUNT AND/OR MOBILE APPLICATION FROM YOUR DEVICE DOES NOT AUTOMATICALLY CANCEL YOUR SUBSCRIPTION.</b>
        </Typography>
        <Typography className={classes.mb4}>
          <b>THESE TERMS OF SERVICE CONTAIN AN ARBITRATION AGREEMENT, WHICH WILL, WITH LIMITED EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION. UNDER THE ARBITRATION AGREEMENT, (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST DIALOGUE ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY) ON AN INDIVIDUAL BASIS.</b>
        </Typography>
        <Typography className={classes.mb4}>
          DiaLoGue. (“DiaLoGue,” “we,” “us,” “our”) provides its services (described below) to you through its website located at <a href="/">thedialogue.id</a> and through its mobile application (the “Application”) and related services (collectively, such services, including any new features and applications, and the Application, the “Service(s)”), subject to the following Terms of Service (as amended from time to time, the “Terms of Service”). We reserve the right, at our sole discretion, to change or modify portions of these Terms of Service at any time. If we do this, we will post the changes on this page and will indicate at the top of this page the date these terms were last revised. We may also notify you, either through the Services’ user interface, push notification and/or alert, in an email notification or through other reasonable means. Any such changes will become effective no earlier than fourteen (14) days after they are posted, except that changes addressing new functions of the Services or changes made for legal reasons will be effective immediately. Your continued use of the Service after the date any such changes become effective constitutes your acceptance of the new Terms of Service.
        </Typography>
        <Typography className={classes.mb4}>
         In addition, when using certain services, you will be subject to any additional terms applicable to such services that may be posted on the Service from time to time, including, without limitation, the Privacy Policy located at <a href="./privacy-policy">https://thedialogue.id/privacy-policy</a>, and the Community Guidelines, and other Policies located at <a href="/">thedialogue.id</a>. All such terms are hereby incorporated by reference into these Terms of Service.
        </Typography>
        <Typography className={classes.mb4}>
          <b>Your Use</b><br />
          The Service is intended to provide helpful, educational, and informative material related to astrology and personal relationships for your entertainment only. It is provided with the understanding that DiaLoGue does not render psychological, health, or any other kind of personal professional services in connection with the Service. If you require personal psychological, health, or other assistance or advice, please consult a competent professional. The Services may suggest that you connect with another User that has received readings similar to your own. DiaLoGue specifically disclaims all responsibility for any liability, loss or risk that is incurred as a consequence, directly or indirectly, of the use of the Service, including any actions you take or refrain from taking as a result of readings you receive through the Service and the actions of any users you connect with through the Service. DiaLoGue further disclaims any legal liability for the reliability of any features related to the Service. You hereby agree to assume full responsibility for any actions you take based on or related to your use of the Service.
        </Typography>
      </Grid>
    </Container>
  );
}

export default TermsOfService;
