import {
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  makeStyles,
  createStyles,
  CircularProgress,
} from "@material-ui/core";
import SuccessImage from "../../assets/circle_success.svg";
import { isMobile } from "react-device-detect";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import services from "../../configs/services";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "100vh",
    },
    card: { padding: "60px 120px" },
    cardMobile: { padding: "60px 20px" },
    cardContent: { padding: 0 },
    header: { fontWeight: "bold" },
    body: { textAlign: "center" },
    image: { marginTop: 46, marginBottom: 80 },
    button: {
      backgroundColor: "#ff3867",
      borderRadius: 30,
      color: "#fff",
      width: 212,
      height: 60,
      "&:hover": {
        color: "#ff3867",
        backgroundColor: "#fff",
        border: "1px solid #ff3867",
      },
    },
    loading: {
      padding: 120,
    },
  })
);

export default function EmailVerification() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // VERIFY
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${services.EMAIL_VERIFICATION}${id}`
      )
      .then(() => setLoading(false))
      .catch((e) => {
        history.push("/");
      });
  }, []);

  function handleLogin() {
    if (isMobile) {
      // APP INTENT
      window.open("dialogueapp://dialogue", "_blank");
    } else {
      // PLAYSTORE
    }
  }

  return (
    <Container maxWidth="md">
      <Grid
        container
        direction={"column"}
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <Card
            elevation={6}
            className={isMobile ? classes.cardMobile : classes.card}
          >
            <CardContent className={classes.cardContent}>
              <Grid container direction="column" alignItems="center">
                {loading ? (
                  <CircularProgress
                    color="secondary"
                    size={80}
                    className={classes.loading}
                  />
                ) : (
                  <>
                    <Grid item>
                      <Typography variant="h6" className={classes.header}>
                        Email Verified!
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" className={classes.body}>
                        Your email has been verified, now you can
                        <br />
                        login to your account
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        src={SuccessImage}
                        width={150}
                        height={150}
                        className={classes.image}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        className={classes.button}
                        disableElevation
                        onClick={handleLogin}
                      >
                        Login
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
