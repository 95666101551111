import React from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    'countdown__box': {
      background: 'rgba(10, 10, 10, 0.9)',
      color: '#fff',
      borderRadius: '6.4px',
      textAlign: 'center',
      padding: '1.5rem'
    },
    'countdown__num': {
      fontSize: '30pt'
    },
    'countdown__text': {
      fontSize: '10pt',
      margin: 0
    }
  })
);

// Replace with desired date 'January 17, 2019 03:24:00', example
// countDownDate.setDate('January 17, 2019 03:24:00').getTime();
const defaultDate = new Date();

const getCountdownValues = (countDownDate) => {
  const now = new Date().getTime();
  const distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  if (distance > 0) {
    return { days, hours, minutes, seconds };
  }

  return { days: '—', hours: '—', minutes: '—', seconds: '—' };
}

const useCountdown = (date) => {
  const countDownDate = new Date(date).getTime();
  const [countdown, setCountdown] = React.useState(getCountdownValues(countDownDate));

  React.useEffect(() => {
    const interval = setInterval(() => setCountdown(getCountdownValues(countDownDate)), 1000);
    return () => clearInterval(interval);
  }, [countDownDate]);

  // return getCountdownValues(countdown);
  return countdown;
}

export default function Countdown({ date = defaultDate }) {
  const countdown = useCountdown(date);
  const classes = useStyles();

  return (
    <Grid container justify="center" className="countdown" spacing={1}>
      <Grid item xs={4} md={2} lg={'auto'}>
        <div className={classes['countdown__box']}>
          <span className={classes['countdown__num']}>{countdown.days}</span>
          <p className={classes['countdown__text']}>Days</p>
        </div>
      </Grid>
      <Grid item xs={4} md={2} lg={'auto'}>
        <div className={classes['countdown__box']}>
          <span className={classes['countdown__num']}>{countdown.hours}</span>
          <p className={classes['countdown__text']}>Hours</p>
        </div>
      </Grid>
      <Grid item xs={4} md={2} lg={'auto'}>
        <div className={classes['countdown__box']}>
          <span className={classes['countdown__num']}>{countdown.minutes}</span>
          <p className={classes['countdown__text']}>Minutes</p>
        </div>
      </Grid>
      <Grid item xs={4} md={2} lg={'auto'}>
        <div className={classes['countdown__box']}>
          <span className={classes['countdown__num']}>{countdown.seconds}</span>
          <p className={classes['countdown__text']}>Seconds</p>
        </div>
      </Grid>
    </Grid>
  );
}
