import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ref, push, set } from 'firebase/database';

import { db } from 'configs/firebase';
import logo from 'assets/logo.png';
import useCommonStyles from 'styles/common';
import useButtonStyles from 'styles/button';
import Countdown from 'components/Countdown';
import TextField from 'components/TextField';
import Modal from 'components/Modal';

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const useClassess = () => {
  const commonStyles = useCommonStyles();
  const buttonStyles = useButtonStyles();

  return { ...commonStyles, ...buttonStyles };
}

export default function Home() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const classes = useClassess();

  function handleChange(value) {
    setEmail(value);
  }

  async function handleSubscribe() {
    const isValid = validateEmail(email);

    if (!isValid) {
      setError(true);
      return false;
    }

    try {
      const payload = { email, created: new Date().getTime() };
      const newSubscriber = push(ref(db, 'subscribtions'));
      await set(newSubscriber, payload);

      setEmail('');
      setOpenModal(true);
    } catch (error) {
      console.log(`Error: ${error.message}`);
    }
  }

  useEffect(() => {
    setError(false);
    setErrorMessage('');
  }, [email]);

  return (
    <Container maxWidth="md">
      <Grid container>
        <Grid item xs={12} style={{ marginTop: '5rem', marginBottom: '2rem' }}>
          <center>
            <img src={logo} width="200" alt="site-logo" />
          </center>
        </Grid>
        <Grid item xs={12}>
          <Countdown date={'October 30, 2022 08:00:00'} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '2rem', textAlign: 'center' }}>
          <Typography variant="h2" className={`${classes.pageSubtitle} ${classes.mt5}`}>
            Coming soon. Subscribe for email notifications of future updates.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '1.6rem' }}>
          <TextField
            name="email"
            placeholder="Enter email"
            value={email}
            onChange={handleChange}
            helperText={errorMessage || 'Invalid Email'}
            error={error}
            showVisibilityIcon={false}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '1.6rem' }}>
          <Button
            variant="contained"
            className={classes.button}
            disableElevation
            onClick={handleSubscribe}
          >
            Get Notified
          </Button>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
          >
            <span className="modal__icon modal__icon--success">
              <CheckCircleOutlineIcon />
            </span>
            <h3 className="modal__title">Thank You!</h3>
            <p className="modal__desc">You are already subscribed on our list</p>
          </Modal>
        </Grid>
      </Grid>
    </Container>
  );
}
