import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MUIModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '.4rem',
    boxShadow: theme.shadows[4],
    padding: theme.spacing(3, 4, 3),
    textAlign: 'center',

    '& .modal__title': {
      fontSize: '24px',
      margin: '16px 0 8px 0'
    },
    '& .modal__desc': {
      margin: 0
    },
    '& .modal__icon': {
      display: 'inline-flex',
      fontSize: '72px'
    },
    '& .modal__icon--success': {
      color: '#5dcb25'
    },
    '& .modal__icon svg': {
      fontSize: 'inherit'
    }
  },
}));

export default function Modal({ open = false, onClose, children }) {
  const classes = useStyles();

  return (
    <MUIModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {children}
        </div>
      </Fade>
    </MUIModal>
  );
};
