import {
  makeStyles,
  createStyles,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useState } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: `gray !important`,
      },
    },
    cssFocused: {},
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "gray !important",
    },
    helperText: {
      marginLeft: 0,
      fontSize: 10,
    },
    input: { width: 360 },
    inputMobile: { width: 240 },
  })
);

export default function ResetPassword({
  placeholder,
  onChange,
  error,
  helperText,
  showVisibilityIcon = true,
  ...rest
}) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(showVisibilityIcon);

  function handleChange(event) {
    onChange(event.target.value);
  }

  return (
    <TextField
      type={showPassword ? "password" : "text"}
      variant="outlined"
      placeholder={placeholder}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
        shrink: true,
      }}
      inputProps={{ style: { textAlign: "center" } }}
      InputProps={{
        classes: !error && {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
        },
        ...(showVisibilityIcon && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }),
      }}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      className={isMobile ? classes.inputMobile : classes.input}
      onChange={handleChange}
      error={error}
      helperText={error && helperText && helperText}
      {...rest}
    />
  );
}
