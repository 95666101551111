import { makeStyles, createStyles } from "@material-ui/core";

const useCommonStyles = makeStyles(() =>
  createStyles({
    pageTitle: {
      fontSize: '44px',
      fontWeight: 'bold',
      textAlign: 'center',
      margin: '32px 0 24px 0'
    },
    pageSubtitle: {
      fontSize: '24px',
      textAlign: 'center'
    },
    // spacings
    mb4: {
      marginBottom: '24px'
    },
    mt5: {
      marginTop: '48px'
    }
  })
);

export default useCommonStyles;
