import React from 'react';
import { Container, Grid, Typography } from "@material-ui/core";

import useCommonStyles from 'styles/common';

function PrivacyPolicy() {
  const classes = useCommonStyles();

  return (
    <Container maxWidth="md">
      <Grid item>
        <Typography variant="h2" className={classes.pageTitle}>
          Privacy Policy
        </Typography>
        <Typography className={classes.mb4}>
          <b>Last updated on August 1, 2021</b>
        </Typography>
        <Typography className={classes.mb4}>
          <b>Our Policy:</b><br />
          Welcome to the website (the “Site”) or the mobile application (the “Application”) of DiaLoGue. (“DiaLoGue”, “we”, “us” and/or “our”). This Privacy Policy has been created to provide information about our company and our user profile services, and related services (together with the Application and Site, the “Services”) to our users and visitors (“Users”, “you”, “your”). This Privacy Policy explains DiaLoGue practices with respect to information that relates to or could reasonably be associated with an individual (“Personal Data”) and other information that we collect from Users. This Privacy Policy is incorporated into and forms part of our Terms of Service available through the Application and at <a href="./terms">thedialogue/terms</a>. Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THIS APPLICATION. 
        </Typography>
        <Typography className={classes.mb4}>
          <b>Information We Collect:</b><br />
          When you interact with us through the Services, we may collect the following Personal Data and other information from you, as further described below:
        </Typography>
        <Typography className={classes.mb4}>
          <b>Personal Data That You Provide Through the Services</b>
          We collect Personal Data from you when you voluntarily provide such information to us, such as when you contact us with inquiries, respond to one of our surveys, register for access to the Application or use certain Services. This information may include: (1) email address; (2) phone number; (3) Facebook ID; (4) Apple ID; and/or (5) photos or GIFs if shared with other Users on the Service. The Services may also allow you to update your status, comment on other Users’ statuses and Personal, communicate with other Users, and upload photos. When you take such actions, you may provide us with Personal Data. Please note, to upload photos to the Service, you may allow access to your camera and photo album.
        </Typography>
        <Typography className={classes.mb4}>
          <b>Profile Information</b>
          We collect information provided from you, including: (1) your name or alias; (2) birthdate; (3) the time of your birth; (4) the country, state, and city of your birth; (5) your gender preference; (6) username; (7) location; and (8) photo (if uploaded) to create your profile. You may set your profile to “private” so other Users, who you have not added as a friend, may not view your profile, but all Users may be able to create Reports (defined below) about how two individuals connect based on the Personal Data you provide in your profile. Further, when your account is set to private, other Users, who you have not added as friends, cannot view your friends, how similar you are, or run a Bond with you.
        </Typography>
        <Typography className={classes.mb4}>
          <b>Contacts</b><br />
          You may choose to share your contacts stored on your phone in order to use certain functions of the Application (like our comparison report feature). We will only use the contact information to help you find friends or contacts who use The Pattern Application if you choose to do so.
        </Typography>
        <Typography className={classes.mb4}>
          <b>By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy.</b>
        </Typography>
        <Typography className={classes.mb4}>
          <b>Other Information:</b>
        </Typography>
        <Typography className={classes.mb4}>
          <b>Automatically Passively Collected Data</b>
          When you interact with DiaLoGue through the Site, we and third parties that provide functionality on the Site may engage, receive, collect and store certain types of information through automatic data collection tools including cookies and similar technologies (as described in more detail below), and encrypted authentication tokens and similar technology. When you interact with us through the Application, we also collect information automatically using different technologies. Such information, which is collected passively using various technologies, may include but is not limited to information about your device and referring/exit pages. DiaLoGue may store such information itself or such information may be included in databases owned and maintained by DiaLoGue affiliates, agents or service providers. The Site may use such information and pool it with other information to track, for example, the total number of visitors to our Site, the number of visitors to each page of our Site, and the domain names of our visitors' Internet service providers. Such information that we collect will allow DiaLoGue to make educated decisions on how to improve the Site and provide a better product Site experience for our users. 
        </Typography>
      </Grid>
    </Container>
  );
}

export default PrivacyPolicy;
